import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { FormControl, InputLabel, MenuItem, Select, FormControlLabel, Checkbox, Popover, TextField, Box, Radio, RadioGroup, Button } from '@mui/material';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Nav from "../../components/navigation/Nav.js";
import Footer from "../../components/footer/Footer.js";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Alert from "@mui/material/Alert";
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; 
import CircularProgress from '@mui/material/CircularProgress';


// Firebase инициализация
firebase.initializeApp({
  apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
  authDomain: "masig-online.firebaseapp.com",
  projectId: "masig-online",
  storageBucket: "masig-online.appspot.com",
  messagingSenderId: "1064560601004",
  appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
  measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

// Функция для генерации уникального ID документа
const generateDocId = () => {
  const randomDigits = Math.floor(1000000 + Math.random() * 9999999);
  const letters = "ABCDEFGHJKLMNPQRSTUVWXYZ";
  const randomLetters =
    letters.charAt(Math.floor(Math.random() * letters.length)) +
    letters.charAt(Math.floor(Math.random() * letters.length)) +
    letters.charAt(Math.floor(Math.random() * letters.length));
  return "CV" + randomDigits + randomLetters;
};

export default function GreenCard() {
  const [idnx, setIdnx] = useState("");
  const [vehicleCertNumber, setVehicleCertNumber] = useState("");
  const [startDay, setStartDay] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [selectedInsurer, setSelectedInsurer] = useState("");
  const [showOrderButton, setShowOrderButton] = useState(false);
  const [showCalculateButton, setShowCalculateButton] = useState(true);
  const [uid, setUid] = useState("");
  const { t } = useTranslation();
  const [idnxError, setIdnxError] = useState("");
  const [vehicleCertNumberError, setVehicleCertNumberError] = useState("");
  const [accept, setAccept] = useState(false); 
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openicon1 = Boolean(anchorEl1);
  const idicon1 = openicon1 ? 'simple-popover-1' : undefined;
  const openicon2 = Boolean(anchorEl2);
  const idicon2 = openicon2 ? 'simple-popover-2' : undefined;
  const [open, setOpen] = useState(false);
  const [zone, setZone] = useState(""); 
  const [zoneChanged, setZoneChanged] = useState(""); 
  const [termInsurance, setTermInsurance] = useState(""); 
  const [termInsuranceChanged, setTermInsuranceChanged] = useState(""); 
  const [loading, setLoading] = useState(false);



  const StyledTextFieldBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    fontFamily: theme.typography.fontFamily, // Наследуем шрифт из темы
    fontSize: theme.typography.body1.fontSize, // Наследуем размер шрифта
    color: theme.palette.text.primary, // Наследуем основной цвет текста
}));


  const resetButtons = () => {
    setShowOrderButton(false);
    setShowCalculateButton(true);
  };

  // Анонимная аутентификация пользователя
  useEffect(() => {
    auth.signInAnonymously()
      .then((userCredential) => {
        setUid(userCredential.user?.uid);
      })
      .catch((error) => {
        console.error("Auth error:", error);
      });
  }, []);


  const handleiconClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleiconClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleiconClose1 = () => {
    setAnchorEl1(null);
  };

  const handleiconClose2 = () => {
    setAnchorEl2(null);
  };

  const handleIdnxChange = (e) => {
    const newIdnx = e.target.value;
    setIdnx(newIdnx);
  
    // Проверяем только после ввода 13 символов
    if (newIdnx.length === 13) {
      if (validateIdnxLastDigit(newIdnx)) {
        setErrorMessage(""); // Сбрасываем ошибку, если значение валидное
      } else {
        setErrorMessage(t('labelIdnoErr')); // Показываем ошибку, если значение неверное  
      }
    } else {
      setErrorMessage(""); // Очищаем ошибку, пока ввод не завершен
    }
  
    
  
    resetButtons();
  };

  const validateIdnxLastDigit = (idnxValue) => {
    if (/^[012]\d{0,12}$/.test(idnxValue)) {
      const D = (
        (parseInt(idnxValue[0]) + parseInt(idnxValue[3]) + parseInt(idnxValue[6]) + parseInt(idnxValue[9])) * 7 +
        (parseInt(idnxValue[1]) + parseInt(idnxValue[4]) + parseInt(idnxValue[7]) + parseInt(idnxValue[10])) * 3 +
        (parseInt(idnxValue[2]) + parseInt(idnxValue[5]) + parseInt(idnxValue[8]) + parseInt(idnxValue[11]))
      ) % 10;
      return D === parseInt(idnxValue[12]);
    } else {
      return false;
    }
  };
  

  const handleZoneChange = (event) => {
    setZone(event.target.value);
    setZoneChanged("true");
    setShowOrderButton(false);
    setShowCalculateButton(true);
};


const handleTermInsuranceChange = (event) => {
  setTermInsurance(event.target.value);
  setTermInsuranceChanged("true");
  setShowOrderButton(false);
    setShowCalculateButton(true);
};

  const handleRegistrationNumberChange = (e) => {
    setVehicleCertNumber(e.target.value);
    setShowOrderButton(false);
    setShowCalculateButton(true);
  };


  const handleDateChange = (newValue) => {
    if (newValue) {
      setStartDay(dayjs(newValue).format("DD.MM.YYYY")); // Форматируем дату в строку
    } else {
      setStartDay(""); // Сбрасываем значение, если дата не выбрана
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);  

  
    // Проверка для IDNX
    if (!validateIdnxLastDigit(idnx)) {
      setErrorMessage(t('labelIdnoErr'));
      return; // Останавливаем дальнейшее выполнение
    } else {
      setIdnxError(""); // Сбрасываем ошибку для idnx, если оно валидно
    }
  
    // Проверка для Vehicle Registration Certificate Number
    if (!/^(\d{9}|B\d{9}|b\d{9})$/.test(vehicleCertNumber)) {
      setVehicleCertNumberError(t('labelVehRegErr'));
      return; // Останавливаем выполнение, если ошибка для vehicleCertNumber
    } else {
      setVehicleCertNumberError(""); // Сбрасываем ошибку для vehicleCertNumber, если оно валидно
    }
  
    // Если все поля валидны, можно отправить запрос или другие действия
  
    const requestData = {
      GreenCardZone: zone,
      IDNX: idnx,
      VehicleRegistrationCertificateNumber: vehicleCertNumber,
      termInsurance
    };
  
    try {
      const response = await axios.post("https://asig24.md/calculate_rcae", requestData, {
        headers: { "Content-Type": "application/json" },
      });
  
      const data = response.data;
      setResponseData(data); // Обновляем responseData
      setLoading(false);

  
      console.log("Response Data:", data); // Для отладки
  
      // Приводим IsSuccess к булевому типу
      const isSuccess = data.IsSuccess === "true";
  
      if (isSuccess) {
        setShowOrderButton(true);
        setShowCalculateButton(false);
        console.log("Order Button:", true, "Calculate Button:", false);
      } else {
        setShowOrderButton(false);
        setShowCalculateButton(true);
        console.log("Order Button:", false, "Calculate Button:", true);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing the request.");
      setShowOrderButton(false); // Скрываем кнопку "Order"
      setShowCalculateButton(true); // Показываем кнопку "Calculate"
    }
  };


  const insurerNameMap = {
    'DONARIS VIENNA INSURANCE GROUP S.A.': 'Donaris',
    'GRAWE CARAT ASIGURARI S.A. ': 'Grawe Carat',
    'ASTERRA GRUP S.A.': 'Asterra Grup',
    'MOLDASIG S.A.': 'Moldasig',
    'MOLDCARGO S.A.': 'Moldcargo',
  };
  
  
  const insurerLogos = {
    Donaris: './Donaris.png',
    'Grawe Carat': './Grawe.png',
    'Asterra Grup': './Asterra.png',
    Moldcargo: './Moldcargo.png',
    Moldasig: './Moldasig.png',
  }; 

  useEffect(() => {
    if (responseData && responseData.Insurers.length > 0) {
      setSelectedInsurer(responseData.Insurers[0].Name); // Устанавливаем первую страховку по умолчанию
    }
  }, [responseData]); // Этот эффект будет вызываться при изменении responseData


  const handleOrder = async () => {
    if (!selectedInsurer) {
      alert("Please select an insurance company before proceeding.");
      return;
    }

    const docId = generateDocId();
    const formattedDate = moment().format("YYYY-MM-DD HH:mm:ss");

    try {
      await db.collection("rcarequests2").doc(docId).set({
        idnx: idnx,
        vehicleRegistrationCertificateNumber: vehicleCertNumber,
        startDay: startDay,
        zone: zone,
        datetime: formattedDate,
        vehicle: responseData.Vehicle,
        period: termInsurance,
        product_type: "GREEN CARD",
        rcaName: responseData.Person,
        uid: uid,
        insuranceCompany: selectedInsurer,
        price: responseData.Insurers.find((insurer) => insurer.Name === selectedInsurer)?.PrimeSumMDL,
      });

      localStorage.setItem("docId", docId);
      localStorage.setItem("uid", uid);

      // Переход на следующую страницу
      window.location.href = `./GreenCard/${docId}`;
    } catch (error) {
      console.error("Error adding document:", error);
    }
  };

  const handleInsurerChange = (e) => {
    setSelectedInsurer(e.target.value);
  };



  return (
    <div>
        <Helmet>
            <title>{t('cardsGreenCard')}</title>
            <meta name="description" content="Calculează prețul și cumpără Cartea Verde online. Rapid și sigur cu Asig24.md obții polița Cartea Verde în câteva minute." />
            <meta name="keywords" content="Cartea Verde, Cartea Verde online, asigurări, calculator Cartea Verde, asigurare auto, Cartea Verde Moldova, asigurări online" />
            <meta name="author" content="Asig24.md" />
            <meta property="og:title" content={t('cardsGreenCard')} />
            <meta property="og:description" content="Calculează prețul și cumpără Cartea Verde online. Rapid și sigur cu Asig24.md obții polița Cartea Verde în câteva minute." />
            <meta property="og:url" content="https://www.asig24.md/GreenCard" />
            <meta property="og:type" content="website"></meta>
            <link rel="canonical" href="https://www.asig24.md/GreenCard" />
        </Helmet>
        <Nav />        
            <div className="parent">
                <div className="forma">     

                <div className="card" id="cartea-verde" style={{ marginBottom: '1rem' }}>
                    <div className="icon icon-cartea-verde"></div>
                    <div className="card-content">
                    <h2>{t('cardsGreenCard')}</h2>
                    <p>{t('greenCardDesc')}</p>   
                </div>
    
  </div>

        <form onSubmit={handleSubmit}>


                        <FormControl fullWidth required style={{ marginBottom: '1rem' }}>
                            <InputLabel>{t('zone')}</InputLabel>
                                <Select 
                                    label={t('zone')}
                                    value={zone} onChange={handleZoneChange}>
                                        <MenuItem value={"Z3"}>{t('z3')}</MenuItem>
                                        <MenuItem value={"Z1"}>{t('z1')}</MenuItem>
                                </Select>
                        </FormControl>



  <TextField
    label={t('labelIdno')}
    style={{ marginBottom: '1rem' }}
    variant="outlined" // Вы можете выбрать "filled" или "standard", если предпочитаете другой стиль
    value={idnx}
    onChange={handleIdnxChange}
    inputProps={{ maxLength: 13 }} // Ограничение на длину ввода
    InputProps={{
      endAdornment: (            
          <InputAdornment disableTypography position="end" className='helpicon'>
              <IconButton onClick={handleiconClick1}  >            
                  <HelpOutlineIcon />            
              </IconButton>
          </InputAdornment>  
      ),                            
  }}
    required
    fullWidth // Полная ширина (опционально)
    error={!!errorMessage} // Устанавливает стиль ошибки
        helperText={errorMessage} // Отображает текст ошибки
  />
  <Popover
        id={idicon1}
        open={openicon1}
        anchorEl={anchorEl1}
        onClose={handleiconClose1}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        >
        <div className="popovermsg">
        {t('popoverMesage')}
        </div>
    </Popover>



<TextField
    label={t('labelVehReg')}
    style={{ marginBottom: '1rem' }}
    variant="outlined" // Можно выбрать "filled" или "standard", если нужно
    value={vehicleCertNumber}
    onChange={handleRegistrationNumberChange} // Функция обработки изменения
    inputProps={{ maxLength: vehicleCertNumber.startsWith('B') || vehicleCertNumber.startsWith('b') ? 10 : 9 }} // Устанавливаем ограничение длины
    InputProps={{
      endAdornment: (
        <InputAdornment disableTypography position="end" className="helpicon">
          <IconButton onClick={handleiconClick2}>
            <HelpOutlineIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
    required
    fullWidth // Полная ширина (опционально)
    error={!!vehicleCertNumberError} // Устанавливает стиль ошибки, если ошибка существует
    helperText={vehicleCertNumberError} // Отображает текст ошибки
/>
<Popover
        id={idicon2}
        open={openicon2}
        anchorEl={anchorEl2}
        onClose={handleiconClose2}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        >
        <div className="popovermsg">
        <div><img src='./certificate-number.jpg' width="100%" maxwidth="200"></img></div>
        </div>
    </Popover>
    

<FormControl fullWidth required style={{ marginBottom: '1rem' }}>
                            <InputLabel>{t('insuredPeriod')}</InputLabel>
                                <Select 
                                    label={t('insuredPeriod')}
                                    value={termInsurance} onChange={handleTermInsuranceChange}>
                                        <MenuItem value={"d15"}>{t('d15')}</MenuItem>
                                        <MenuItem value={"m1"}>{t('m1')}</MenuItem>
                                        <MenuItem value={"m2"}>{t('m2')}</MenuItem>
                                        <MenuItem value={"m3"}>{t('m3')}</MenuItem>
                                        <MenuItem value={"m4"}>{t('m4')}</MenuItem>
                                        <MenuItem value={"m5"}>{t('m5')}</MenuItem>
                                        <MenuItem value={"m6"}>{t('m6')}</MenuItem>
                                        <MenuItem value={"m7"}>{t('m7')}</MenuItem>
                                        <MenuItem value={"m8"}>{t('m8')}</MenuItem>
                                        <MenuItem value={"m9"}>{t('m9')}</MenuItem>
                                        <MenuItem value={"m10"}>{t('m10')}</MenuItem>
                                        <MenuItem value={"m11"}>{t('m11')}</MenuItem>
                                        <MenuItem value={"m12"}>{t('m12')}</MenuItem>
                                </Select>
                        </FormControl>


  <FormControl fullWidth >
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={`${t('labelDateLocale')}`}
    >
      <DatePicker
        label={t('labelDate')}
        disablePast={true}
        value={startDay ? dayjs(startDay, "DD.MM.YYYY") : null} // Преобразуем строку в объект Dayjs
        onChange={handleDateChange}
        slotProps={{
          textField: {
            variant: 'outlined',
            required: true,
          },
          toolbar: {
            toolbarTitle: `${t('labelDateToolbarTitle')}`,
            toolbarPlaceholder: "__",
            toolbarFormat: "DD.MM.YYYY",
            hidden: false,
          },
        }}
      />
    </LocalizationProvider>
  </FormControl>

<FormControl>
        <FormControlLabel          
            control={
            <Checkbox required  checked={accept} onChange={(event) => setAccept(event.target.checked)}  />
            }            
            label={
                <div className="checkbox">
                    {t('labelCheckBox')}
                </div>
            }/>          
    </FormControl>



          <div align="center">
                                {showCalculateButton && <Button type="submit" color="error"  variant="contained" sx={{ mt: '1rem' }}>
                                {t('calcBtn')}
                                </Button>}
                            </div>

        </form>



        {loading ? (
        <div className="loading" align="center">
          <CircularProgress />
        </div>
      ) : (
        <>
{responseData && responseData.IsSuccess === "true" && (
  <Box sx={{ mt: '1rem' }}>
    <Alert severity="success">
                                                <div>{t('vehicle')}: <b>{responseData.Vehicle}</b></div>
                                                </Alert>



                                                <FormControl fullWidth>
    <RadioGroup
        name="insurer"
        value={selectedInsurer}
        onChange={handleInsurerChange}
        sx={{ mt: '1rem' }}
    >
        {responseData.Insurers.map((insurer, index) => (
            <StyledTextFieldBox key={index}>
                {/* Clickable container */}
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    onClick={() => handleInsurerChange({ target: { value: insurer.Name } })} // Make the whole field clickable
                    sx={{ cursor: 'pointer'}}
                >
                    {/* Radio Button */}
                    <Radio
                        value={insurer.Name}
                        style={{ padding: '0 8px' }}
                        inputProps={{ 'aria-label': insurerNameMap[insurer.Name] || insurer.Name }}
                    />
                    {/* Logo and Name Section */}
                    <Box display="flex" alignItems="center" width="100%">
                        {/* Logo */}
                        <img 
                            src={insurerLogos[insurerNameMap[insurer.Name]] || 'default-logo.png'} 
                            alt={`${insurerNameMap[insurer.Name] || insurer.Name} Logo`} 
                            style={{ width: 30, height: 30, marginRight: '8px' }} 
                        />
                        {/* Name and Price */}
                        <Box flex={1} display="flex" justifyContent="space-between" alignItems="center">
                            <span>{insurerNameMap[insurer.Name] || insurer.Name}</span>
                            <span style={{ textAlign: 'right', fontWeight: 'bold' }}>{insurer.PrimeSumMDL} MDL</span>
                        </Box>
                    </Box>
                </Box>
            </StyledTextFieldBox>
        ))}
    </RadioGroup>
</FormControl>
  
</Box>
)}

{responseData && responseData.IsSuccess === "false" && (
  <Alert severity="error"><b>{t('errorTextRes')}</b></Alert>
)}



<div align="center">
        {showOrderButton && (
          <Button onClick={handleOrder} variant="contained" sx={{ mt: '1rem' }}>{t('orderBtn')}</Button>
        )}
      </div>
      </>
      )}
      </div>
      <Footer />
    </div>
    </div>
  );
}
