import React, { useState, useEffect } from "react";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"
import { useTranslation } from 'react-i18next';

export default function Contact() {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState('ro'); 

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage); // Смена языка
    setLanguage(selectedLanguage); // Обновление состояния
  }
  return (
    <div>
      <Nav />
      <div className="parent">
                <div className="condText">
                <h1>{t('contacts')}</h1>
                    <br></br>


<b>PRIMGROUP-ASIG S.R.L.</b><br></br>
IDNO: 1012600036968<br></br>
mun. Chișinău, str. Miron Costin 14<br></br>
Tel: +373 61 111 101<br></br>
Email: support@asig24.md<br></br>
<br/><br/>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.207302949127!2d28.863080982747846!3d47.04898726908779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97cfbc73e799d%3A0x81a49c24ee82bfc0!2zU3RyYWRhIE1pcm9uIENvc3RpbiAxNCwgQ2hpyJlpbsSDdSwg0JzQvtC70LTQsNCy0LjRjw!5e0!3m2!1sru!2s!4v1684181825191!5m2!1sru!2s" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>





</div>
            </div>
      <Footer />
    </div>
  );
}