import React, { useState, useEffect } from "react";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"
import { useTranslation } from 'react-i18next';



export default function Termsofservices() {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState('ro'); 

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage); // Смена языка
    setLanguage(selectedLanguage); // Обновление состояния
};

  return (
    <div>
        <Nav />
            <div className="parent">
                <div className="condText">

                                 {/* Рендерим HTML из JSON */}
                                 <div dangerouslySetInnerHTML={{ __html: t('TermsofservicesText') }} />

                </div>
            </div>
      

      <Footer />
    </div>
  );
}