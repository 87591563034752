import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Nav from "../../../components/navigation/Nav.js"
import Footer from "../../../components/footer/Footer.js"
import "../style.css";
import { useParams, useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import { Button,  FormControl, InputLabel, ListItemIcon, MenuItem, Select, TextField } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import CashIcon from '../../../components/icons/cash.png'; 
import CardIcon from '../../../components/icons/card.png'; 
import Bank from '../../../components/icons/bank.png';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from 'axios'; 
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import robotoFont from './roboto.js';
import 'jspdf-autotable';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';




firebase.initializeApp({
    apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
    authDomain: "masig-online.firebaseapp.com",
    projectId: "masig-online",
    storageBucket: "masig-online.appspot.com",
    messagingSenderId: "1064560601004",
    appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
    measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

export default function RCAs() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [idnx, setIdnx] = useState("");    
    const [vehicleRegistrationCertificateNumber, setVehicleRegistrationCertificateNumber] = useState("");
    const [unlimitedPersons, setUnlimitedPersons] = useState("");
    const [personIsJuridical, setPersonIsJuridical] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [persons, setPersons] = useState("");
    const [bonusMalus, setBonusMalus] = useState("");
    const [price, setPrice] = useState("");
    const [deliveryPrice, setDeliveryPrice] = useState("0.00");
    const [amount, setAmount] = useState("");
    const [operatingModes, setOperatingModes] = useState("");
    const [name, setName] = useState("");
    const [rcaName, setRcaName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [deliveryMethod, setDeliveryMethod] = useState("online");
    const [email, setEmail] = useState("");
    const [vehicle, setVehicle] = useState("");
    const [adress, setAdress] = useState("");
    const [product_type, setProductType] = useState("");
    const [ownership, setOwnership] = useState("");
    const [insuranceCompany, setInsuranceCompany] = useState("");
    const [startDay, setStartDay] = useState("");
    const startDate = dayjs(startDay, "DD.MM.YYYY");
    const endDay = startDate.add(1, 'year').subtract(1, 'day').format('DD.MM.YYYY');
    const [paymentMethod, setPaymentMethod] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [bankLoading, setBankLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [cashPaymentMethod, setCashPaymentMethod] = useState(false);
    const [cashInTerminalPaymentMethod, setCashInTerminalPaymentMethod] = useState(false);
    const [bankCardPaymentMethod, setBankCardPaymentMethod] = useState(false);
    const [description, setDescription] = useState(' Front test transaction'); 
    const [language, setLanguage] = useState(''); 
    const [currency, setCurrency] = useState('498'); 
    const [status, setStatus] = useState(''); 
    const [transactionStatus, setTransactionStatus] = useState('');

    const { i18n, t } = useTranslation();

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage); // Смена языка
        setLanguage(selectedLanguage); // Обновление состояния
    };


    const getUserIpAddress = async () => { 
      try { 
        const response = await axios.get('https://api.ipify.org/?format=json'); 
        const { ip } = response.data; 
        return ip; 
      } catch (error) { 
        console.error(error); 
        return ''; 
      } 
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const doc = await db.collection("rcarequests2").doc(id).get();
        setData(doc.data());
        setIdnx(doc.data().idnx);
        setVehicleRegistrationCertificateNumber(doc.data().vehicleRegistrationCertificateNumber);
        setUnlimitedPersons(doc.data().unlimitedPersons); 
        setPersonIsJuridical(doc.data().personIsJuridical);
        setCompanyName(doc.data().companyName);
        setPersons(doc.data().persons);
        setBonusMalus(doc.data().bonusMalus);
        setPrice(doc.data().price);
        setDeliveryPrice(doc.data().deliveryPrice);
        setAmount(doc.data().amount);
        setOperatingModes(doc.data().operatingModes);
        setRcaName(doc.data().rcaName);
            setVehicle(doc.data().vehicle);
        setName(doc.data().name);
        setRcaName(doc.data().rcaName);
        setPhoneNumber(doc.data().phoneNumber);
        setDeliveryMethod(doc.data().deliveryMethod);
        setEmail(doc.data().email);
        setProductType(doc.data().product_type);
        setAdress(doc.data().adress);
        setOwnership(doc.data().ownership);
        setInsuranceCompany(doc.data().insuranceCompany);
        setStartDay(doc.data().startDay);
        setLoading(false);
      } catch (error) {
        console.log(error);
        navigate('/');
      }
    };
    fetchData();
  }, [id]);

  const handlePaymentChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleConfirmPaymentMethod = async (paymentMethod) => {
    try {
      await db.collection("rcarequests2").doc(id).update({
        paymentMethod,
      });
      setSubmitted(true);
    } catch (error) {
      console.log(error);
    }
  };  

  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      'send_to': 'AW-994026003/rNJaCOTlwLMYEJPE_tkD',
      'event_callback': callback
    });
    return false;
  }


  const handleCardPay = async (e) => { 
    e.preventDefault(); 
    gtag_report_conversion();
    setBankLoading(true);
    const clientIpAddress = await getUserIpAddress(); 
   
    // Convert the decimal amount to an integer without decimal places 
    const amountToSend = Math.round(amount * 100); 
   
    try { 
      const response = await axios.post('https://asig24.md/create-transaction', { 
        description: id, 
        language: i18n.language, 
        currency: currency, 
        amount: amountToSend, 
        clientIpAddress, 
      }); 
   
      const { transactionId } = response.data; 
   
      setStatus('Transaction created. Redirecting to the payment page...'); 
      window.location.href = `https://maib.ecommerce.md:443/ecomm01/ClientHandler?trans_id=${transactionId}`; 
    } catch (error) { 
      setStatus('Error creating transaction. Please try again.'); 
    } 
  };
  
  
  
  


return (
    <div>
      <Helmet>
            <title>{id}</title>
            <meta name="description" content="Calculează și cumpără asigurare RCA online. Rapid și sigur cu Asig24.md obții polița ta de asigurare RCA în câteva minute." />
            <meta name="keywords" content="RCA, RCA online, asigurări, calculator RCA, asigurare auto, asigurare RCA Moldova, asigurări online" />
            <meta name="author" content="Asig24.md" />
            <meta property="og:title" content="Asigurare RCA online" />
            <meta property="og:description" content="Calculează și cumpără asigurare RCA online. Rapid și sigur cu Asig24.md obții polița ta de asigurare RCA în câteva minute." />
            <meta property="og:url" content="https://www.asig24.md/RCA" />
            <meta property="og:type" content="website"></meta>
            <link rel="canonical" href="https://www.asig24.md/RCA" />
        </Helmet>        
        <Nav />
            <> {loading ? ( <div align="center" className="loading"><CircularProgress /></div> ) : ( <>

        <div className="parent">
            <div className="forma">

                <div className="orderdetails">
                    <div align="center">
                        <b>{t('orderText1')}
                        <br></br>
                        {t('orderText2')}</b><br></br><br></br>
                        <h1>{t('orderNo')} {id}</h1>
                        {transactionStatus !== "OK" && ( <p>{t('orderCheckDetails')}</p> )} 
						            {transactionStatus === "OK" && ( <div><CheckCircleOutlineIcon className='transactionStatusIcon' sx={{ fontSize: 40 }} /><h2>{t('orderPaid')}</h2></div> )}
                        <br></br><br></br>
                    </div>
                    <div className="orderdetailsrow">{t('insured')} <b>{idnx} {rcaName}</b></div>
                    <div className="orderdetailsrow">{t('bonusMalus')} <b>{bonusMalus}</b></div>
                    <div className="orderdetailsrow">{t('labelVehReg')} <b>{vehicleRegistrationCertificateNumber} {vehicle}</b></div>
                    <div className="orderdetailsrow">{t('insuredPeriod')} <b>{startDay} - {endDay}</b></div>
                    <div className="orderdetailsrow">{t('selectedInsuranceCompany')} <b>{insuranceCompany}</b></div>
                    <div className="orderdetailsrow">{t('contactPerson')}: <b>{name}</b></div>
                    <div className="orderdetailsrow">{t('labelPhone')}: <b>{phoneNumber}</b></div>
                    {deliveryMethod === "online" && email !== "" && ( <div className="orderdetailsrow">Email: <b>{email}</b></div> )}
                    {deliveryMethod === "deliveryOnAdress" && ( <div className="orderdetailsrow">{t('deliveryAdress')}: <b>{adress}</b></div> )}
                    {deliveryMethod === "fromOffice" && ( <div className="orderdetailsrow">{t('pickupAdress')}: <b>{t('officeAdress')}</b></div> )}

                    
                    <Divider  />
                    <br></br>
                    <div className="orderdetailsrow">{t('insurancePrice')} <b>{price} {t('Lei')}</b></div>
                    {deliveryPrice > 0 && (<div className="orderdetailsrow">{t('deliveryPrice')} <b>{deliveryPrice} {t('Lei')}</b></div>)}
                    <Divider textAlign="right"><div className="orderdetailsrow"><b>{t('total')} {amount} {t('LEI')}</b></div></Divider>
                </div>

                {transactionStatus !== "OK" && (
				<div>
                    <Accordion className="paymentMethod" expanded={expanded === 'panel1'} onChange={handlePaymentChange('panel1')}>
                        <AccordionSummary
                            onClick={() => {
                                setCashPaymentMethod(false);
                                setCashInTerminalPaymentMethod(false);
                                setBankCardPaymentMethod(true);
                                handleConfirmPaymentMethod("BankCard");
                            }}
                        >
                          <Typography sx={{ width: '33%', flexShrink: 0 }}>
        <img src={CardIcon} alt="Card bancar" height="65" /> 
        </Typography>
        <div  className="paymentMethod">
            <b>{t('cardPayment')}</b>
        </div>
    </AccordionSummary>
    <AccordionDetails>
        <Divider  />
        <Typography>     
        {t('cardPaymentText1')}
        </Typography>
        {transactionStatus !== "OK" && (
                            <div className="paybutton" align="center"> 
                                  <div>
                                    <p style={{ fontSize: '13px' }}>
                                    {t('cardPaymentText2')} <a href="https://asig24.md/Termsofservices">{t('Termsofservices')}</a> {t('and')} <a href="https://asig24.md/Rcaterms">{t('insuranceTerms')}</a>
                                    </p><br></br>
                                    <Button onClick={handleCardPay} variant="contained" type="submit">{t('payBtn')}</Button>
                                  </div>
                            </div>)}
                            {bankLoading && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                            <CircularProgress />
                        </div>
                    )}
    </AccordionDetails>
                    </Accordion>

                    

                    <Typography component="div" style={{ height: '1rem' }} />
                    <Accordion className="paymentMethod" expanded={expanded === 'panel3'} onChange={handlePaymentChange('panel3')}>
                        <AccordionSummary
                            onClick={() => {
                                setCashPaymentMethod(false);
                                setCashInTerminalPaymentMethod(false);
                                setBankCardPaymentMethod(true);
                                handleConfirmPaymentMethod("Cash");
                            }}
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            <img src={CashIcon} alt="Card bancar" height="65" />
                            </Typography>
                            <div  className="paymentMethod">
                                <b>{t('cashPayment')}</b>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Divider  />
                            <Typography>    
                            {t('cashPaymentText')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div> 
				)}                            
                                 
                        
            </div>
        </div>
        <Footer /> 
        </> )} </>
    </div>

    
  );
}